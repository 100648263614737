import { useRef, useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { IconButton, Button } from "@mui/material";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { ContextPGIMode } from "../../Hooks/PGIModeContext";
import { allVideoDurationapi } from "src/DAL/Programmes/Programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { handleVideoList } = useContentSetting();
  const location = useLocation();
  const previousLocation = usePreviousLocation();

  // Custom hook to store the previous location
  function usePreviousLocation() {
    const ref = useRef();
    useEffect(() => {
      ref.current = location;
    }, [location]);
    return ref.current;
  }

  const handleVideoPause = async (type) => {
    let allVideoList = JSON.parse(localStorage.getItem("video_list"));
    let data = [{}];
    if (allVideoList?.length >= 1) {
      data = { recording_array: allVideoList };
    }
    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    const result = await allVideoDurationapi(data, type);
    if (result.code === 200) {
    } else {
    }
  };
  useEffect(() => {
    if (previousLocation?.pathname == "/replay-library") {
      handleVideoList(localStorage.getItem("video_list"));
      if (location.pathname !== previousLocation?.pathname) {
        handleVideoPause("replay_library");
      }
    } else {
      handleVideoList(localStorage.getItem("video_list"));
      let videoType = localStorage.getItem("video_type");
      if (location.pathname !== previousLocation?.pathname) {
        handleVideoPause(videoType);
        console.log("Path changed:", location.pathname);
        console.log(videoType, "videoTypevideoTypevideoType");
        console.log(
          localStorage.getItem("video_list"),
          "videoooooooooooooooooooooooooooo"
        );
      }
    }
    localStorage.removeItem("video_list");
    localStorage.removeItem("video_type");
  }, [location, previousLocation]);

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  return (
    <RootStyle>
      {/* <ContextPGIMode> */}
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
      {/* </ContextPGIMode> */}
    </RootStyle>
  );
}
