import React, { useState, useEffect } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

// const card = {
//   name: "jhon",
//   expiry: "12/12",
//   cardNumber: "5151515151515151",
//   cvc: "123",
// };

const ShowCard = ({ card }) => {
  const navigate = useNavigate();
  //   const [name, setName] = useState("");
  const [inputs, setInputs] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });

  //   setInputs((prevState) => ({
  //     ...prevState,
  //     [name]: card.name,
  //     //   [expiry]: card.expiry,
  //     //   [number]: card.number,
  //     //   [cvc]: card.cvc,
  //   }));

  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleNavigate = () => {
    navigate(`/change-card`);
  };

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      ["name"]: card.name,
      ["expiry"]: card.expiry,
      ["number"]: card.cardNumber,
      ["cvc"]: card.cvc,
    }));
  }, [card]);

  return (
    <div className="container">
      <h2>Card Details</h2>
      <div className="row mt-5">
        <div className="col-md-4 how-img">
          <Cards
            cvc={inputs.cvc}
            expiry={inputs.expiry}
            focused={inputs.focus}
            name={inputs.name}
            number={inputs.number}
          />
        </div>
        <div className="col-md-8">
          <form className="text-center mt-1 mx-auto w-100">
            <div className="row">
              <div className="col-lg-6 cl-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="number"
                  value={inputs.number}
                  label="Card Number"
                  disabled
                  variant="outlined"
                  placeholder="Card Number"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-lg-6 cl-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="name"
                  disabled
                  value={inputs.name}
                  label="Card Holder Name"
                  variant="outlined"
                  placeholder="Card Holder Name"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-lg-6 cl-md-6 col-sm-12 ">
                <TextField
                  id="outlined-basic"
                  name="expiry"
                  disabled
                  value={inputs.expiry}
                  label="Valid Thru"
                  variant="outlined"
                  placeholder="Expiry"
                  fullWidth
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-lg-6 cl-md-6 col-sm-12 ">
                <TextField
                  id="outlined-basic"
                  name="cvc"
                  disabled
                  value={inputs.cvc}
                  label="CVC"
                  variant="outlined"
                  placeholder="CVC"
                  fullWidth
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
            </div>
          </form>
          <div className="col-12 text-end mt-4">
            <button className="small-contained-button" onClick={handleNavigate}>
              Change Card
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowCard;
